import './Blog.css';

const FinancniVykazy = () => {
    return (
        <div className="blogContent">

            <div className='blogContainer'>
                <h1>Finanční výkazy firem</h1>

                <p className='blogText'>
                    Finanční výkazy jsou dokumenty, které popisují finanční stav firem. Finanční výkaz obsahuje výkaz zisku a ztrát a rozvahu (aktiva a pasiva). Finanční výkazy firem můžete prohledávat v aplikaci <a href="/">financni-vykazy.cz</a>.
                </p>

                <img className="blogImage" src={require("./financni vykazy.png")} />
            </div>

            <div className='blogContainer'>
                <h2>Výkaz zisku a ztrát</h2>
                <p className='blogText'>Výkaz zisku a ztrát popisuje výsledek hospodaření za sledované období.</p>
            </div>
            <div className='blogContainer'>
                <h2>Rozvaha - Aktiva</h2>
                <p className='blogText'>Rozvaha - Aktiva je dokument popisující strukturu majetku firmy.</p>

            </div>
            <div className='blogContainer'>
                <h2>Rozvaha - Pasiva</h2>
                <p className='blogText'>Rozvaha - Pasiva je dokument popisující zdroje krytí majetku.</p>
            </div>
        </div>
    )
}

export default FinancniVykazy;